import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../containers/SaasModern/Documentation';
import ribbon_options from '../../../../../common/src/assets/image/ribbon_options.png';
import options from '../../../../../common/src/assets/image/options.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        PrintableCal provides the ability to customize the item template used
        for events and tasks in generated calendars, create or modify category
        colors, connect to the internet through a proxy server, and modify how
        events and tasks are color-coded when imported from various data
        sources. These settings can be accessed by clicking the{' '}
        <strong>Options </strong>button on the PrintableCal tab of the ribbon
        menu in Excel, Word, and PowerPoint.
      </p>
      <p>
        <Image alt="" src={ribbon_options} style={{ width: 855 }} />
      </p>
      <p>
        After clicking the <strong>Options </strong>button, the Options window
        will appear. The window provides tabs along the left side for modifying
        the various options within PrintableCal.
      </p>
      <p>
        <Image alt="" src={options} style={{ width: 891 }} />
      </p>
      <p>
        More information about each of the options tabs can be found in the
        sections linked below:
      </p>
      <ul>
        <li>
          <Link to="/Documentation/Options/Item-Template">Item Template</Link>
        </li>
        <li>
          <Link to="/Documentation/Options/Category-Colors">
            Category Colors
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Options/Proxy-Server">Proxy Server</Link>
        </li>
        <li>
          <Link to="/Documentation/Options/Calendar-Data-Source-Options">
            Calendar Data Source Options
          </Link>{' '}
          (Exchange Server, Google Calendar, Outlook (Local), and VueMinder)
        </li>
      </ul>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Options"
      commentsId="commentsplus_post_154_489"
      title="PrintableCal Options"
      description="PrintableCal provides the ability to customize the item template used for events and tasks in generated calendars, create or modify category colors, connect to the internet through a proxy server, and modify how events and tasks are color-coded when imported from various data sources."
      keywords="printable calendar, download calendars, import calendars, calendar templates, proxy server, proxy options"
      content={content}
    />
  );
};

export default Documentation;
